export default {
    OS_NAME: process.env.VUE_APP_OS_NAME,
    ACCENT_COLOR: "#05ace7",
    SUPPORTED_DEVICES: [
        {
            name: "Pixel 6",
            model: "oriole",
        },
        {
            name: "Pixel 6 Pro",
            model: "raven",
        },
        {
            name: "Pixel 6a",
            model: "bluejay",
        },
        {
            name: "Pixel 7",
            model: "panther",
        },
        {
            name: "Pixel 7 Pro",
            model: "cheetah",
        },
    ],
    RELEASE_VARIANTS: {
        full: {
            description: "Full version of SecureOS with clean install.",
            suffix: "",
        },
        beta: {
            description: "Beta version of SecureOS with clean install.",
            suffix: "-beta",
        },
    },
};
