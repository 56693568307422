import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import Config from "../config";
//import colors from 'vuetify/es5/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: true,
        themes: {
            light: {
                primary: Config.ACCENT_COLOR,
                //primary: "#05ace7",
                secondary: "#2196f3",
                accent: "#00bcd4",
                error: "#f44336",
                warning: "#ffc107",
                info: "#673ab7",
                success: "#4caf50",
            },
            dark: {
                primary: Config.ACCENT_COLOR,
                //primary: "#05ace7",
                secondary: "#2196f3",
                accent: "#00bcd4",
                error: "#f44336",
                warning: "#ffc107",
                info: "#673ab7",
                success: "#4caf50",
            },
        },
    },
});