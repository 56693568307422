/* eslint-disable no-useless-escape */
/* eslint-disable no-useless-escape */
<template>
    <v-container class="d-flex flex-column flex-grow-1">
<!--        <v-switch v-model="loginMethod" label="Use token login method" color="primary"></v-switch>-->
        <!-- Your form here, which will differ based on loginMethod -->
        <v-form ref="form" v-model="valid" lazy-validation>
<!--            <v-text-field v-if="loginMethod" v-model="token" label="Token" required></v-text-field>-->

                <v-text-field v-model="username" :counter="10" :rules="usernameRules" label="Username"
                    required></v-text-field>
                <v-text-field autocomplete="current-password" :value="userPassword" label="Enter password"
                    hint="Your password passed! Password rules are not meant to be broken!"
                    :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'" @click:append="() => (value = !value)"
                    :type="value ? 'password' : 'text'" :rules="[rules.password]"
                    @input="(_) => (userPassword = _)"></v-text-field>

            <v-btn :disabled="!valid" color="success" class="mr-4" @click="validate">
                Validate
            </v-btn>

            <v-btn color="error" class="mr-4" @click="reset">
                Reset Form
            </v-btn>
        </v-form>
        <v-snackbar v-model="snackbar.show" :color="snackbar.color">
            {{ snackbar.message }}
            <template v-slot:action="{ attrs }">
                <v-btn color="pink" text v-bind="attrs" @click="snackbar.show = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script>
import { post } from "axios";
import store from "store";
export default {
    props: ["device", "blobStore", "active"],
    data: () => ({
        valid: true,
        snackbar: {
            show: false,
            color: 'success',
            message: '',
        },
        username: "",
        userPassword: "",
        value: true,
        loginMethod: true, // true for token, false for username and password
        token: '',
        usernameRules: [
            (v) => !!v || "Name is required",
            (v) =>
                (v && v.length <= 10) || "Name must be less than 10 characters",
        ],
        rules: {
            required: (value) => !!value || "Required.",
            password: (value) => {
                // eslint-disable-next-line no-useless-escape
                const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
                return (
                    pattern.test(value) ||
                    "Min. 8 characters with at least one capital letter, a number and a special character."
                );
            },
        },
    }),
    watch: {
        active: async function (newState) {
            if (newState) {
                this.saEvent("step_login");
            }
        },
    },

    methods: {
        validate() {
            // If loginMethod is true, use the token login method
            if (this.loginMethod) {
                this.validateToken();
            } else {
                // Otherwise, use the traditional login method
                this.validateCredentials();
            }
        },
        validateToken() {
            console.log("Validating token");
            this.snackbar = { show: true, color: 'success', message: 'Success' };
            this.$bubble("nextStep");
        },
        validateCredentials() {
            const api = `${process.env.VUE_APP_LOGIN_URL}`;
            const url = api + "/authenticate";
            var data = {
                username: this.username,
                password: this.userPassword,
            };
            post(url, data).then((response) => {
                if (response.status === 200) {
                    store.set("token", response.data.token)
                    store.set("userid", JSON.stringify({ username: response.data.username, id: response.data.id }));
                    this.snackbar = { show: true, color: 'success', message: 'Success' };
                    this.$bubble("nextStep");
                }
            }).catch((error) => {
                console.log(error);
                this.snackbar = { show: true, color: 'error', message: 'Invalid Credentials' };
            });
        },
        reset() {
            this.$refs.form.reset();
            this.snackbar = { show: false, color: '', message: '' };
        },
    },
};
</script>